@import 'antd/dist/antd.css';
@import "aos/dist/aos.css";
@import 'react-lazy-load-image-component/src/effects/blur.css';
@font-face {
  font-family: 'Poppins-Black';
  src: url("./Assets/Fonts/poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url("./Assets/Fonts/poppins/Poppins-Black.ttf");
}
@font-face {
  font-family: 'Poppins-Light';
  src: url("./Assets/Fonts/poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url("./Assets/Fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: 'Poppins';
  src: url("./Assets/Fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: 'Avenir';
  src: url("./Assets/Fonts/avenir/AvenirLTStd-Book.otf");
}

@font-face {
  font-family: 'Avenir-Bold';
  src: url("./Assets/Fonts/avenir/AvenirLTStd-Black.otf");
}

@font-face {
  font-family: 'Julietta';
  src: url("./Assets/Fonts/julietta.ttf");
}
body {
  margin: 0;
  min-width: 350px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
